import { userContext } from '@components/context/userContext'
import { LocationInputRefContext } from '@components/organisms/ProductPageSidePanel'
import { cn } from '@lib/utils'
import { MapPin } from 'lucide-react'
import { FC, useContext, useEffect } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import { isCoordinateInsidePolygon } from './GoogleMapsLoader'
// import {ge} from ''

interface GoogleMapsPlaceSuggestionsProps {
  setSelected: (location: string) => void
}

const GoogleMapsPlaceSuggestions: FC<{ className?: string }> = ({ className }) => {
  const {
    ready,
    suggestions: { status, data },
    value,
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete()

  const locationContext = useContext(userContext)
  const locationInputRefContext = useContext(LocationInputRefContext)
  useEffect(() => {
    if (locationContext.currentLocationCoords) {
      const currentAddress = locationContext.currentLocationCoords.displayAdress
      setValue(currentAddress, false)
    }
  }, [locationContext.currentLocationCoords])

  const handleSelect = async (address: string) => {
    setValue(address, false)
    clearSuggestions()
    const result = await getGeocode({ address })
    console.log(result)
    const { lat, lng } = await getLatLng(result[0])

    const isInside = isCoordinateInsidePolygon({ lat: lat, lng: lng })
    console.log(isInside, 'is inside')
    locationContext.setLocationCoords({
      displayAdress: address,
      latitude: lat,
      longitude: lng,
      isInsideTheCity: isInside
    })
  }

  const ref = useOnclickOutside(() => {
    clearSuggestions()
  })
  return (
    <div ref={ref} className={cn('relative', className)}>
      <input
        placeholder="search"
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        value={value}
        className=" text-neutral-700 text-ellipsis flex items-center focus-visible:ring-offset-0 border-0 focus:border-0 outline-none focus:outline-none shadow-none focus-visible:ring-0 p-0 m-0"
        ref={locationInputRefContext}
      />
      {status === 'OK' && (
        <div className="absolute flex flex-col items-center border-surface-50 bg-white  gap-2 w-full rounded-sm z-50 border left-0">
          {data.map(({ place_id, description }, index) => (
            <div
              className={` text-neutral-700 label_small flex gap-3 px-5 py-2 cursor-pointer hover:bg-primary-50 hover:text-primary-500 `}
              key={place_id}
            >
              <div className=" flex-grow-0">
                <MapPin className=" text-sm w-[16px]" />
              </div>
              <div onClick={() => handleSelect(description)}>{description}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default GoogleMapsPlaceSuggestions
