'use client'
import WhatsappIcon from '@assets/icons/whatsapp-social-media-svgrepo-com.svg'
import DeliveryDatePicker from '@components/atoms/DeliveryDatePicker'
import PincodeInput from '@components/atoms/PincodeInput'
import Stepper from '@components/atoms/Stepper'
import ProductPageSidePanelIndividualBox from '@components/molecules/ProductPageSidePanelIndividualBox'
import UserCustomizationInput from '@components/molecules/UserCustomizationInput'
import {
  DropdownSelectionProps,
  ValueIdType,
  customizationProps,
  stepperChoiceProps
} from '@components/types/CustomizationTypes'
import { Button } from '@components/ui/button'
import { ShoppingCart } from 'lucide-react'
import Image from 'next/image'
import {
  FC,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import AppDivider from '@components/atoms/AppDivider'
import RatingPreviewCardUnderProductSidePanel from '@components/atoms/RatingPreviewCardUnderProductSidePanel'
import { addToCartFnc } from '@components/context/CartContext'
import { ProductDetails } from '@components/context/ProductPageContext'
import { convertToProduct, userContext } from '@components/context/userContext'
import PrimaryDropdown from '@components/molecules/productSidepanel/PrimaryDropdown'
import SecondaryDropdown from '@components/molecules/productSidepanel/SecondaryDropdown'
import GetTransportationDetails from '@lib/GetTransportationDetails'
import ProductPriceUtils from '@lib/ProductPriceUtils'
import { convertToNearestNine, safeParseJson } from '@lib/utils'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter, useSearchParams } from 'next/navigation'
import React from 'react'
import { getClickAndFbClientId } from '@app/(auth)/orders/status/[paymentId]/_components/ServerOrderDetails'
import { sendGTMEvent } from '@next/third-parties/google'
import useCartStore from '@store/CartStore'

export interface VendorDetails {
  dropTarget: string
  pickTarget: string
  vendor: string
}
interface ProductPageSidePanelProps {
  price: string
  discountedPrice: string
  userCustomization?: string
  commision: string
  vendorDetails: VendorDetails
  productId: string
}

export const LocationInputRefContext = createContext<RefObject<HTMLInputElement> | undefined>(
  undefined
)

// starting date for picking slot
const twoDaysFromNow = new Date()
twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 3)
const startingDate = twoDaysFromNow

const ProductPageSidePanel: FC<ProductPageSidePanelProps> = ({
  userCustomization,
  discountedPrice,
  price,
  commision,
  vendorDetails,
  productId
}) => {
  const productDetails = useContext(ProductDetails)
  const searchParams = useSearchParams()

  // this ref is used to access location input
  const locationInputRef = useRef<HTMLInputElement>(null)
  const boxRefs = useRef<(RefObject<HTMLDivElement> | undefined)[]>([])

  const customizableData: customizationProps | undefined = useMemo(
    () => (userCustomization ? JSON.parse(userCustomization) : undefined),
    []
  )
  useEffect(() => {
    let tmp = 0

    if (customizableData?.textCustomizations) {
      tmp += customizableData?.textCustomizations.length
    }

    boxRefs.current = Array(tmp + 1)
      .fill(null)
      .map((_, i) => boxRefs.current[i] || React.createRef())
  }, [customizableData?.textCustomizations])

  const [animateErrorInputs, setAnimateErrorInputs] = useState(false)
  const [basePrice, setBasePrice] = useState(parseFloat(price))
  const [priceBeforeDiscount, setPricebeforeDiscount] = useState(0)
  const [stepperCount, setStepperCount] = useState<undefined | { title: string; count: number }>(
    undefined
  )
  const [dropDownoptionsSelected, setDropdownOptionsSelected] = useState<DropdownSelectionProps[]>(
    []
  )
  const { getTransportationDetaisl } = GetTransportationDetails()
  const [customizationTextInput, setCutomizationTextInput] = useState<
    { title: string; content: string }[]
  >([])
  const [deliveryCharge, setDeliveryCharge] = useState<number | undefined>(undefined)
  const [tmpPrice, setTmpPrice] = useState(basePrice)
  const [primaryDropdownMultiplicationFactorDets, setPrimaryDropdownMultiplicationFactorDets] =
    useState<false | { currentCoefficientFactor: number; cost: number }>(
      customizableData?.dropDownCustomizationMain?.isMultiplicationFactor
        ? { currentCoefficientFactor: 1, cost: 0 }
        : false
    )
  const [stepperMultiplicationFactorDets, setStepperMultiplicationFactorDets] = useState<
    false | { currentChoice: stepperChoiceProps }
  >(
    customizableData?.stepperCustomization
      ? {
          currentChoice: customizableData.stepperCustomization.choices[0]
        }
      : false
  )
  const [submitted, setSubmitted] = useState(false)
  const locationContext = useContext(userContext)
  const { getPriceWithDropDowns } = ProductPriceUtils()
  const { data: session } = useSession()
  const [isInWishlist, setIsInWishlist] = useState(false)
  const { wishlist } = useContext(userContext)
  const { wishlistStatus, modifyWishlist } = useContext(userContext)
  const history = useRouter()

  const vendorRawData = useMemo(() => JSON.parse(vendorDetails.vendor), [])
  // I made a mistake in admin board, lat lng is coming as seperate keys here
  const vendorArr = useMemo(
    () =>
      Object.keys(vendorRawData)
        .filter((ele) => ele !== 'lat' && ele !== 'lng')
        .map((ele) => vendorRawData[ele]) as ValueIdType[],
    []
  )

  useEffect(() => {
    if (wishlist) setIsInWishlist(wishlist.some((wish) => wish.productId === productId))
  }, [productId, wishlist])

  // useEffect(() => {
  //   if (vendorDetails && locationContext.currentLocationCoords) {
  //     // const vendorDetails = JSON.parse(vendorDetails.ve)
  //     const vendorLocation: LatLong = vendorDetails.vendor
  //       ? JSON.parse(vendorDetails.vendor)
  //       : undefined
  //     // const vendorDistances =useVe
  //     console.log(vendorArr, ' is when its 5 per km')
  //     if (vendorLocation) {
  //       const getDeliveryCharge = async () => {
  //         if (locationContext.currentLocationCoords) {
  //           const vendorDetailsArray = await nearestVendorDetails({
  //             coords: {
  //               latitude: locationContext.currentLocationCoords.latitude.toString(),
  //               longitude: locationContext.currentLocationCoords.longitude.toString()
  //             },
  //             vendors: vendorArr
  //           })

  //           const charge = await findTransportationPrice(vendorDetails, userCustomization)
  //           if (charge) {
  //             // TODO: add logic for day/night
  //             const price =
  //               (vendorDetailsArray[0].distanceMatrix.elements[0].distance.value / 1000) *
  //               charge.dayTime
  //             setDeliveryCharge(Math.ceil(price))
  //           }
  //         }
  //       }
  //       getDeliveryCharge()
  //     }
  //   }
  // }, [locationContext.currentLocationCoords])

  useEffect(() => {
    const getPrice = async () => {
      await getPriceWithDropDowns({
        basePrice: basePrice,
        stepperCount: stepperCount?.count,
        dropdownOptions: dropDownoptionsSelected,
        primaryDropdownMultiplicationFactorDets: primaryDropdownMultiplicationFactorDets,
        stepperMultiplicationFactorDets: stepperMultiplicationFactorDets
      }).then((price) => {
        // console.log({ price })
        setTmpPrice(price)
      })
    }
    getPrice()

    // console.log(tmpPrice, 'tmpPrice')
  }, [
    dropDownoptionsSelected,
    primaryDropdownMultiplicationFactorDets,
    stepperCount,
    stepperMultiplicationFactorDets
  ])

  useEffect(() => {
    setCurrentPrice(calcPriceWithDiscounts(tmpPrice))
    setPricebeforeDiscount(Math.ceil(calcOriginalPrice(tmpPrice)))
  }, [tmpPrice])

  useEffect(() => {
    //updating primary dropdown coeff if it's a coeff factor
    if (customizableData?.dropDownCustomizationMain && dropDownoptionsSelected[0]) {
      setPrimaryDropdownMultiplicationFactorDets((prev) => ({
        cost: parseFloat(dropDownoptionsSelected[0].choice.cost),
        currentCoefficientFactor: parseFloat(dropDownoptionsSelected[0].choice.coefficient!!)
      }))
    }
  }, [dropDownoptionsSelected[0]])

  useEffect(() => {
    //updating stepper coeff details
    if (stepperCount) {
      setStepperMultiplicationFactorDets({
        currentChoice: getStepperCoefficient(stepperCount.count)!!
      })
      //updating base price acc to steper
      setBasePrice(parseFloat(getStepperCoefficient(stepperCount.count)!!.unitCost))
    }
  }, [stepperCount])

  useEffect(() => {
    if (customizableData?.stepperCustomization) {
      setStepperCount((prev) => ({
        count: parseFloat(customizableData.stepperCustomization!.minRange),
        title: prev!.title
      }))
    }
  }, [])

  const calcPriceWithDiscounts = useCallback((price: number) => {
    // TODO: this tag might change in the future - it should be discount perc in the DB
    const discountPerc = parseFloat(discountedPrice)
    const afterCommision = price + (price * parseFloat(commision)) / 100
    const priceAfterDiscount = (afterCommision * (100 - discountPerc)) / 100
    return convertToNearestNine(priceAfterDiscount)
  }, [])

  const calcOriginalPrice = useCallback((price: number) => {
    const afterCommision = price + (price * parseFloat(commision)) / 100
    return afterCommision
  }, [])

  const [currentPrice, setCurrentPrice] = useState(calcPriceWithDiscounts(basePrice))

  const getStepperCoefficient = useCallback((stepperCount: number) => {
    if (customizableData?.stepperCustomization) {
      const stepper = customizableData.stepperCustomization

      const choice = stepper.choices.find(
        (ele) =>
          stepperCount >= parseFloat(ele.rangeFrom) && stepperCount <= parseFloat(ele.rangeTo)
      ) as stepperChoiceProps
      return choice
    }
  }, [])

  const cartContext = useCartStore()

  const handleWishlist = async () => {
    if (session && !isInWishlist && productDetails)
      modifyWishlist({ type: 'ADD_TO_WISHLIST', product: convertToProduct(productDetails.product) })
    else if (session && isInWishlist && productDetails)
      modifyWishlist({
        type: 'REMOVE_FROM_WISHLIST',
        product: convertToProduct(productDetails.product)
      })
    else history.push('/signin')
  }

  useEffect(() => {
    //to animate the errors when fields are empty
    if (animateErrorInputs) {
      const indexesToBeAnimated: number[] = []
      if (locationInputRef && locationInputRef.current) {
        locationInputRef.current.focus()
        indexesToBeAnimated.push(0)
      }
      // if(customizableData?.dropDownCustomizationMain){

      // }

      indexesToBeAnimated.map((ele) => {
        boxRefs.current[ele]?.current?.classList.add('animate-shake')
      })

      const timer = setTimeout(() => {
        indexesToBeAnimated.map((ele) => {
          boxRefs.current[ele]?.current?.classList.remove('animate-shake')
        })
        setAnimateErrorInputs(false)
      }, 2000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [animateErrorInputs])
  const productCategory = safeParseJson(productDetails!.product.categoryId) as ValueIdType[]
  const producOccasion =
    productDetails!.product.ocassionId !== ''
      ? (safeParseJson(productDetails!.product.ocassionId) as ValueIdType[])
      : [{ value: 'Theme Decor' }]
  const handleToAddCart = async () => {
    sendGTMEvent({
      currency: 'INR',
      contents: [],
      event: 'add_to_cart',
      value: currentPrice.toString(),
      content_ids: productDetails?.product.productId,
      content_type: producOccasion[0].value,
      content_category: productCategory[0].value,
      content_name: productDetails?.product.productName
    })
    if (!locationContext.currentLocationCoords) {
      setAnimateErrorInputs(true)
      return
    }

    if (
      !cartContext?.cartItems
        .map((ele) => ele.productId)
        .includes(productDetails!.product.productId)
    ) {
      window.scrollTo({ top: 100, behavior: 'smooth' })
      setSubmitted(true)
      let numberOfCustomizations = 0
      if (customizableData) {
        if (customizableData?.dropDownCustomizationMain) {
          numberOfCustomizations = 1
        }
        if (customizableData?.dropDownCustomizationSecondary) {
          if (customizableData.dropDownCustomizationMain) {
            numberOfCustomizations = 1 + customizableData?.dropDownCustomizationSecondary.length
          } else {
            numberOfCustomizations = customizableData?.dropDownCustomizationSecondary.length
          }
        }
      }

      if (dropDownoptionsSelected.length === numberOfCustomizations) {
        for (const selection of dropDownoptionsSelected) {
          if (!selection) {
            return
          }
        }
      } else {
        return
      }
      if (productDetails) {
        const defaultTransportaion = JSON.parse(vendorDetails.dropTarget) as ValueIdType[]
        const defaultPriceDetails = await getTransportationDetaisl(defaultTransportaion[0].id)

        if (defaultPriceDetails) {
          cartContext?.updateCart({
            method: 'add',
            item: {
              price: currentPrice,
              productId: productDetails.product.productId,
              quantity: 1,
              userCustomizationData: {
                dropDowns: dropDownoptionsSelected,
                textInput: customizationTextInput,
                stepper: stepperCount
              },
              discountPerc: parseFloat(productDetails.product.discountedPrice),
              name: productDetails.product.productName,
              imageIds: JSON.parse(productDetails.product.imageIds),
              originalPrice: productDetails.price,
              vendorIds: vendorArr,
              transportationCostPerKm: {
                day: defaultPriceDetails.costperkmday,
                night: defaultPriceDetails.costperkmnight
              },
              deliveryPrice: '',
              distance: '0',
              nearestVendorId: '',
              cartId: '',
              addressId: cartContext.selectedAddressId ? cartContext.selectedAddressId : ''
            }
          })

          const ip = await fetch('/api/getClientHeaders')
          const ipAd: { ip: string; ua: string } = await ip.json()
          // const { clickId, browserId } = getClickAndFbClientId(searchParams)
          // await sendPixelEvent({
          //   type: 'addToCart',
          //   payload: {
          //     custom_data: {
          //       productId: productDetails.product.productId,
          //       productName: productDetails.product.productName
          //     },
          //     event_source_url: `https://eventshoppe.in/product/${productDetails.product.productId}`,
          //     event_time: Math.floor(Date.now() / 1000),
          //     user_data: {
          //       client_ip_address: ipAd.ip,
          //       client_user_agent: ipAd.ua,
          //       email: session ? [session.user.emailId] : undefined,
          //       firstName: session ? session.user.firstName : undefined,
          //       lastName: session ? session.user.lastName : undefined,
          //       phone: session ? [session.user.phoneNumber] : undefined,
          //       city: 'Bangalore',
          //       fbBrowserId: browserId ?? undefined,
          //       fbClickId: clickId ?? undefined
          //     }
          //   }
          // })
          if (session) {
            const prod = {
              deliveryprice: 0,
              discountedprice: productDetails.product.discountedPrice,
              imageids: JSON.parse(productDetails.product.imageIds).join(','),
              price: productDetails.price,
              productId: productDetails.product.productId,
              productname: productDetails.product.productName,
              quantity: 1,
              transportationcostperkm: JSON.stringify({
                day: defaultPriceDetails.costperkmday,
                night: defaultPriceDetails.costperkmnight
              }),
              userCustomizationData: {
                dropDowns: dropDownoptionsSelected,
                textInput: customizationTextInput,
                stepper: stepperCount
              },
              userId: session.user.userId,
              vendorids: JSON.stringify(vendorArr),
              deliverydate: cartContext?.deliveryDate,
              timeslot: cartContext?.deliverySlot,
              distance: '',
              nearestVendor: '',
              addressId: locationContext.addresses[0].addressid
            }
            console.log(prod)
            const cartId = await addToCartFnc(prod)
            console.log({ ipAd })

            cartContext?.updateCart({ method: 'setId', cartId: cartId, productId: prod.productId })
          }
        }
      }
    }
  }

  const whatsappMessage = useMemo(() => {
    const str = encodeURIComponent(
      `hi, I was looking at this product ${productDetails?.product.productName} - https://eventshoppe.in/product/${productDetails?.product.productId}. `
    )
    return str
  }, [productDetails])

  return (
    <LocationInputRefContext.Provider value={locationInputRef}>
      <div className="w-full relative ">
        <div className=" relative flex-col rounded-md shadow-sm md:p-8 md:border border-neutral-100 bg-white">
          <div className="flex flex-col-reverse md:flex-col">
            <div className=" flex flex-col-reverse md:flex-row md:gap-2 md:items-center">
              <span className=" headline_large text-textHeader flex gap-1">
                <span>₹</span>
                {currentPrice.toLocaleString()}
              </span>
              <div className=" flex gap-2 items-center">
                <span className=" line-through text-neutral-500 flex gap-1">
                  <span>₹</span> {priceBeforeDiscount}
                </span>
                <span className=" label_medium text-primary-500">({discountedPrice}% OFF)</span>
              </div>
            </div>
            <div className="list_medium text-neutral-500 mb-4">
              {locationContext.currentLocationCoords ? (
                locationContext.currentLocationCoords.isInsideTheCity ? (
                  <>
                    {deliveryCharge ? (
                      <span>+₹ {deliveryCharge} delivery charge (inclusive of all taxes)</span>
                    ) : (
                      <span>
                        Earliest delivery by{' '}
                        {/* {startingDate.getDate() + ' ' + month[startingDate.getMonth()]} */}
                        {startingDate.toDateString()}
                      </span>
                    )}
                  </>
                ) : (
                  <>We don't deliver at this place</>
                )
              ) : (
                <>Share location to know availability</>
              )}
            </div>

            <div className="">
              <RatingPreviewCardUnderProductSidePanel
                productId={productDetails?.product.productId}
              />
            </div>
          </div>

          <div className=" flex flex-col gap-4 w-full">
            <ProductPageSidePanelIndividualBox className="m-0" ref={boxRefs.current[0]}>
              <div className=" pb-2 md:pb-0">Location</div>
              <div
                id="pincode-holder"
                className="rounded md:rounded-none shadow-sm md:shadow-none border md:border-none px-4 md:px-0 h-12 md:h-10 flex items-center w-full justify-between"
              >
                <PincodeInput />
              </div>
            </ProductPageSidePanelIndividualBox>
            <ProductPageSidePanelIndividualBox id="date-picker-id">
              <DeliveryDatePicker
                onDateSelect={(date) => console.log(date)}
                startingDate={startingDate}
                showTimeSleots
              />
            </ProductPageSidePanelIndividualBox>

            {customizableData?.textCustomizations
              ? customizableData.textCustomizations.map((cust, ind) => (
                  // ref starts with index 2, assuming dropdown main is there, 0 is occupied by location box
                  <ProductPageSidePanelIndividualBox
                    ref={boxRefs.current[ind + 2]}
                    key={cust.title}
                  >
                    <div key={cust.title}>
                      <div className=" pb-2 md:pb-0">{cust.title}</div>
                      <div className=" border md:border-none rounded md:rounded-none shadow md:shadow-none">
                        <UserCustomizationInput
                          maxCharacters={parseFloat(cust.charcterLimit)}
                          onChange={(e) => {
                            setCutomizationTextInput((prev) => {
                              const tmp = [...prev]
                              tmp[ind] = { content: e, title: cust.title }
                              return tmp
                            })
                          }}
                          placeholder={cust.placeholder}
                          title={cust.title}
                        />
                      </div>
                    </div>
                  </ProductPageSidePanelIndividualBox>
                ))
              : null}

            {customizableData?.dropDownCustomizationMain ? (
              <PrimaryDropdown
                data={customizableData.dropDownCustomizationMain}
                onSelection={(val) => {
                  setDropdownOptionsSelected((prev) => {
                    const tmp = [...prev]
                    tmp[0] = val
                    return tmp
                  })
                }}
                isCompulsory={customizableData.dropDownCustomizationMain.isCompulsory}
                isSubmitted={submitted}
                //all drop down customization mains at index 1
                // ref={boxRefs.current[1]}
              />
            ) : null}
            {customizableData?.dropDownCustomizationSecondary ? (
              <SecondaryDropdown
                data={customizableData.dropDownCustomizationSecondary}
                onSelect={(values) => {
                  // console.log({ values })
                  setDropdownOptionsSelected((prev) => {
                    const tmp = [prev[0], ...values]
                    return tmp
                  })
                }}
                isSubmitted={submitted}
              />
            ) : null}
            {customizableData?.stepperCustomization ? (
              <div className="flex gap-3 flex-col justify-start items-start md:p-4 md:border-neutral-200 md:border md:rounded">
                <div>{customizableData?.stepperCustomization.title}</div>
                <Stepper
                  onChange={(val) => {
                    setStepperCount({
                      count: val,
                      title: customizableData.stepperCustomization!.title
                    })
                  }}
                  jumpCount={parseFloat(customizableData?.stepperCustomization.stepperJumpCount)}
                  rangeMax={parseFloat(customizableData?.stepperCustomization.maxRange)}
                  rangeMin={parseFloat(customizableData?.stepperCustomization.minRange)}
                />
              </div>
            ) : null}
          </div>

          <AppDivider short />
          <div className="sticky bottom-0 bg-white upward-shadow md:shadow-none -mx-4">
            {/* <GreenBarForMobile>Get additional discount at checkout</GreenBarForMobile> */}
            <div className=" flex p-2.5 my-2.5 md:my-0 md:flex-col-reverse gap-2.5">
              {/* <Button
                onClick={handleWishlist}
                variant="outline"
                className=" hidden md:flex border-primary-500 "
              >
                <div className=" text-primary-500 title_medium flex gap-3 items-center py-8 ">
                  {isInWishlist ? (
                    'WISHLISTED'
                  ) : (
                    <>
                      {wishlistStatus === 'LOADING' && session ? (
                        <span className="animate-spin text-primary-500">
                          <svg
                            className=" animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="rgb(232 0 113)"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                          </svg>
                        </span>
                      ) : (
                        <Heart />
                      )}
                      WISHLIST
                    </>
                  )}
                </div>
              </Button> */}
              <div>
                <Link
                  href={`https://wa.me/918123400066?text=${whatsappMessage}`}
                  className="w-full"
                >
                  <Button variant={'outline'} className="flex items-center w-full">
                    <Image src={WhatsappIcon} width={20} height={20} alt="whatsapp" />
                    <div className="text-gray-700 font-medium pl-1">WhatsApp</div>
                  </Button>
                </Link>
              </div>
              {/* <Button
                onClick={handleWishlist}
                variant="secondary"
                className=" flex md:hidden bg-primary-50 "
              >
                <div className=" text-primary-500 mobile_semibold flex gap-3 items-center py-8 ">
                  {isInWishlist ? (
                    'WISHLISTED'
                  ) : (
                    <>
                      {wishlistStatus === 'LOADING' && session ? (
                        <span className="animate-spin text-primary-500">
                          <svg
                            className=" animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="rgb(232 0 113)"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                          </svg>
                        </span>
                      ) : (
                        <Heart />
                      )}
                      WISHLIST
                    </>
                  )}
                </div>
              </Button> */}
              <Button
                // disabled={!locationContext.currentLocationCoords?.isInsideTheCity}
                className={` flex-1 md:flex-auto`}
                onClick={handleToAddCart}
              >
                <div className=" text-white mobile_semibold md:title_medium flex gap-3 items-center py-8 ">
                  {!cartContext?.cartItems
                    .map((ele) => ele.productId)
                    .includes(productDetails!.product.productId) ? (
                    <>
                      {' '}
                      <ShoppingCart />
                      ADD TO CART
                    </>
                  ) : (
                    <Link href={'/shopping-cart/cart'}>Go to Cart</Link>
                  )}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </LocationInputRefContext.Provider>
  )
}

export default ProductPageSidePanel
