'use client'
import locateMe from '@assets/locateMe.svg'
import { LocationProps, userContext } from '@components/context/userContext'
import { cn } from '@lib/utils'
import Image from 'next/image'
import React, { useContext } from 'react'
import { getGeocode } from 'use-places-autocomplete'
import { isCoordinateInsidePolygon } from './GoogleMapsLoader'

interface LocateMeProps {
  setErrorCode?: (errorCode: number | null) => void
  onLocationChange: (tmp: { latitude: number; longitude: number; displayAdress: string }) => void
  className?: string
}

const LocateMeButton: React.FC<LocateMeProps> = ({
  onLocationChange,
  setErrorCode = () => {
    return null
  },
  className
}) => {
  const locationContext = useContext(userContext)

  const handleLocateMe = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const address = await getGeocode({
            location: { lat: position.coords.latitude, lng: position.coords.longitude }
          })

          const tmp: LocationProps = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            displayAdress: address[0].formatted_address,
            isInsideTheCity: isCoordinateInsidePolygon({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            })
          }

          console.log(tmp, locationContext.currentLocationCoords)
          locationContext.setLocationCoords(tmp)
          onLocationChange(tmp)
        },
        (err) => {
          console.log(err)
          setErrorCode(err.code)
        }
      )
    } else {
      // setError('Geolocation is not supported by this browser.')
    }
  }

  return (
    <div className={cn(className)}>
      <button
        type="button"
        onClick={handleLocateMe}
        className="flex gap-2 label_small text-primary-400 whitespace-nowrap h-full  w-full justify-end pr-3"
      >
        <Image src={locateMe} alt="Click this button to get location automatically" />
        <div className="hidden md:block">Locate Me</div>
      </button>
      {/* {location && (
        <p>
          Latitude: {location.latitude}, Longitude: {location.longitude}
        </p>
      )} */}
    </div>
  )
}

export default LocateMeButton
