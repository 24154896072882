'use client'
import { cn } from '@lib/utils'
import { Minus, Plus } from 'lucide-react'
import { FC, useEffect, useState } from 'react'

interface StepperProps {
  rangeMin?: number
  rangeMax?: number
  jumpCount?: number
  onChange: (currentVal: number) => void
  className?: string
}

const Stepper: FC<StepperProps> = ({
  jumpCount = 1,
  rangeMax = 100,
  rangeMin = 0,
  onChange,
  className
}) => {
  const [count, setCount] = useState(rangeMin)

  const handleBtn = (opt: 'increment' | 'decrement') => {
    if (opt == 'increment') {
      setCount((prev) => {
        if (prev + jumpCount > rangeMax) {
          return rangeMax
        }
        return prev + jumpCount
      })
    } else {
      setCount((prev) => {
        if (prev - jumpCount < rangeMin) {
          return rangeMin
        }
        return prev - jumpCount
      })
    }
  }

  useEffect(() => {
    onChange(count)
  }, [count])
  return (
    <div className={cn('flex gap-2 justify-center items-center', className)}>
      <div
        className={cn(
          `border-[.2px] border-gray-400 rounded-md p-2 flex justify-center items-center transition-all select-none`,
          count == rangeMin ? 'opacity-30' : 'cursor-pointer hover:shadow-inner'
        )}
        onClick={() => handleBtn('decrement')}
      >
        <Minus />
        {/* - */}
      </div>
      <div className=' mx-2 text-20'>{count}</div>
      <div
        className={cn(
          `border-[.2px] border-gray-400 rounded-md p-2 flex justify-center items-center transition-all select-none`,
          count == rangeMax ? 'opacity-30' : 'cursor-pointer hover:shadow-inner'
        )}
        onClick={() => handleBtn('increment')}
      >
        <Plus />
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M5 12h14"/><path d="M12 5v14"/></svg> */}
        {/* + */}
      </div>
    </div>
  )
}

export default Stepper
