'use client'
import { isCoordinateInsidePolygon, library } from '@components/atoms/GoogleMapsLoader'
import { AddressProps } from '@lib/AuthUtils'
import { DbProductProps, Product } from '@lib/GetProducts'
import { sendGAEvent } from '@next/third-parties/google'
import { useLoadScript } from '@react-google-maps/api'
import LocationStore from '@store/LocationStore'
import { useSession } from 'next-auth/react'
import React, {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState
} from 'react'
import { getGeocode } from 'use-places-autocomplete'
import useCartStore from '@store/CartStore'
export interface LocationProps {
  latitude: number
  longitude: number
  displayAdress: string
  isInsideTheCity?: boolean
}

function safeJsonParse(str: string): unknown {
  try {
    const parsed = JSON.parse(str)
    if (typeof parsed === 'object' && parsed !== null) {
      Object.keys(parsed).forEach((key) => {
        parsed[key] = safeJsonParse(parsed[key])
      })
    }
    return parsed
  } catch (e) {
    return str // return the original string if it's not JSON
  }
}

export function convertToProduct(product: DbProductProps): Product {
  const convertedProduct: any = {}

  Object.keys(product).forEach((key) => {
    const value = product[key as keyof DbProductProps]
    // Only parse if value is not undefined
    convertedProduct[key] = value !== undefined ? safeJsonParse(value) : value
  })

  return convertedProduct as Product
}

export interface WishListProps {
  id: string
  userId: string
  productId: string
  product: Product
}

export interface DbWishListProps {
  id: string
  userId: string
  productId: string
  product: DbProductProps
}

type Action =
  | { method: 'add'; address: AddressProps }
  | { method: 'clear' }
  | { method: 'edit'; address: AddressProps }
  | {
      method: 'addresses'
      addresses: AddressProps[]
    }

type WishlistAction =
  | { type: 'GET_WISHLIST' }
  | { type: 'ADD_TO_WISHLIST'; product: Product }
  | { type: 'REMOVE_FROM_WISHLIST'; product: Product }
  | { type: 'SET_WISHLIST'; wishlistedProducts: WishListProps[] }
  | { type: 'CHECK_IN_WISHLIST'; productId: string; callback: (isInWishlist: boolean) => void }

const wishlistReducer = (state: WishListProps[], action: WishlistAction): WishListProps[] => {
  switch (action.type) {
    case 'ADD_TO_WISHLIST':
      return [...new Set([...state])] // Ensure no duplicates
    case 'REMOVE_FROM_WISHLIST':
      return state.filter((id) => id.productId !== action.product.productId)
    case 'SET_WISHLIST':
      return action.wishlistedProducts
    default:
      return state
  }
}

const addressReducer = (state: AddressProps[], action: Action): AddressProps[] => {
  switch (action.method) {
    case 'addresses':
      return action.addresses
    case 'add':
      return [...state, action.address]
    case 'clear':
      return []
    default:
      return []
  }
}

export const getAddresses = async (userId: string) => {
  const res = await fetch('/api/address/get', {
    method: 'POST',
    body: JSON.stringify({ userId: userId })
  })
  if (!res.ok) {
    throw new Error('failed to fetch adresses')
  }
  const response: { addresses: AddressProps[] } = await res.json()
  return response
}

export const getWishlist = async (userId: string) => {
  const res = await fetch('/api/wishlist/fetch', {
    method: 'POST',
    body: JSON.stringify({ userId: userId })
  })
  if (!res.ok) {
    throw new Error('failed to save adresses')
  }
  const response = await res.json()
  return response
}

export type SaveAddressType = Omit<AddressProps, 'addressid'>
export const saveUserAddress = async (address: SaveAddressType) => {
  const res = await fetch('/api/address/save', {
    method: 'POST',
    body: JSON.stringify(address)
  })
  if (!res.ok) {
    throw new Error('failed to save adresses')
  }
  const response = await res.json()
  return response
}

export const userContext = createContext<{
  currentLocationCoords: LocationProps | undefined
  setLocationCoords: (data: LocationProps | undefined) => void
  isMapsLoaded: boolean
  addresses: AddressProps[]
  setAddresses: React.Dispatch<Action> | undefined
  wishlist: WishListProps[] // Array of product IDs
  modifyWishlist: (action: WishlistAction) => void
  wishlistStatus: string
}>({
  currentLocationCoords: undefined,
  setLocationCoords: () => undefined,
  isMapsLoaded: false,
  addresses: [],
  setAddresses: undefined,
  wishlistStatus: 'LOADING',
  wishlist: [],
  modifyWishlist: (action) => undefined
})

const UserContext: FC<{ children: ReactNode }> = ({ children }) => {
  const { locationData, setLocationData } = LocationStore()
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAzDW-p4LAypo8jcnGNOQaClWUXGr-Yxso',
    libraries: library
  })

  useEffect(() => {
    if (!locationData && isLoaded) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const address = await getGeocode({
            location: { lat: position.coords.latitude, lng: position.coords.longitude }
          })

          const isInside = isCoordinateInsidePolygon({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          })
          const tmp: LocationProps = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            displayAdress: address[0].formatted_address,
            isInsideTheCity: isInside
          }

          setLocationData(tmp)
        },
        (err) => {
          // setError(err.message)
        }
      )
    }
  }, [locationData, isLoaded])

  const [addresses, dispatch] = useReducer(addressReducer, [])

  const { data: session } = useSession()
  const cartContext = useCartStore()

  const [wishlist, wishlistDispatch] = useReducer(wishlistReducer, [])

  const [wishlistStatus, setWishlistStatus] = useState('LOADING')

  const modifyWishlist = useCallback(
    async (action: WishlistAction) => {
      const userId = session?.user?.userId ?? '' // Assuming userId is available in session data
      if (!userId) {
        // console.error('User ID is not available')
        return
      }

      switch (action.type) {
        case 'GET_WISHLIST': {
          setWishlistStatus('LOADING')
          try {
            const response = await fetch('/api/wishlist/fetch', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ userId })
            })
            if (!response.ok) throw new Error('Failed to fetch wishlist')
            const data = (await response.json()) as {
              status: string
              statusCode: string
              wishlistitems: DbWishListProps[]
            }
            // Correctly apply conversion to each product in the wishlist
            const convertedItems: WishListProps[] = data?.wishlistitems.map((item) => ({
              ...item,
              product: convertToProduct(item.product)
            }))
            wishlistDispatch({ type: 'SET_WISHLIST', wishlistedProducts: convertedItems })
            setWishlistStatus(data.status)
          } catch (error) {
            // console.error('Error fetching wishlist:', error)
          }
          break
        }
        case 'ADD_TO_WISHLIST': {
          setWishlistStatus('LOADING')
          const { product } = action
          const addResponse = await fetch('/api/wishlist/add', {
            method: 'POST',
            body: JSON.stringify({ userId, productId: product.productId })
          })
          if (addResponse.ok) {
            sendGAEvent('event', 'WISHLIST_ADD_CLK', {
              productId: product.productId,
              product: product.productName
            })
            const data = (await addResponse.json()) as {
              status: string
              statusCode: string
              wishlistitems: DbWishListProps[]
            }
            // Correctly apply conversion to each product in the wishlist
            const convertedItems: WishListProps[] = data?.wishlistitems.map((item) => ({
              ...item,
              product: convertToProduct(item.product)
            }))
            wishlistDispatch({ type: 'SET_WISHLIST', wishlistedProducts: convertedItems })
            setWishlistStatus(data.status)
          }
          break
        }
        case 'REMOVE_FROM_WISHLIST': {
          setWishlistStatus('LOADING')
          const { product } = action
          const removeResponse = await fetch('/api/wishlist/delete', {
            method: 'POST',
            body: JSON.stringify({ userId, productId: product.productId })
          })
          if (removeResponse.ok) {
            sendGAEvent('event', 'WISHLIST_REMOVE_CLK', {
              productId: product.productId,
              product: product.productName
            })
            const data = (await removeResponse.json()) as {
              status: string
              statusCode: string
              wishlistitems: DbWishListProps[]
            }
            // Correctly apply conversion to each product in the wishlist
            const convertedItems: WishListProps[] = data?.wishlistitems.map((item) => ({
              ...item,
              product: convertToProduct(item.product)
            }))
            wishlistDispatch({ type: 'SET_WISHLIST', wishlistedProducts: convertedItems })
            setWishlistStatus(data.status)
          }
          break
        }
        case 'CHECK_IN_WISHLIST': {
          const { productId, callback } = action
          const checkResponse = await fetch('/api/wishlist/check', {
            method: 'POST',
            body: JSON.stringify({ userId, productId })
          })
          if (checkResponse.ok) {
            const { isInWishlist } = await checkResponse.json() // Assuming API returns this flag
            callback(isInWishlist) // Calling the callback with the result
          }
          break
        }
        default:
          break
      }
    },
    [session?.user?.userId, wishlistDispatch]
  )

  // Inside UserContext component
  // useEffect(() => {
  //   // console.log('Session userID:', session) // Check session availability
  //   if (session?.user?.userId) {
  //     modifyWishlist({ type: 'GET_WISHLIST' })
  //   }
  // }, [session?.user?.userId])

  const setAddresses = useCallback(async (userId: string) => {
    const addresses = await getAddresses(userId)
    if (addresses && addresses.addresses.length > 0) {
      dispatch({ method: 'addresses', addresses: addresses.addresses })
      cartContext?.updateCart({
        method: 'setAddressId',
        addressId: addresses?.addresses[0].addressid ?? 'none'
      })
    }
  }, [])

  const setContextsDebounced = useCallback(async (userId: string) => {
    setAddresses(userId)
  }, [])

  useEffect(() => {
    if (session) {
      // setting the addresses store
      // setContextsDebounced(session.user.userId)
      setAddresses(session.user.userId)
      // getCartItems({ userId: session.user.userId, deliveryDate: store })
    }
  }, [session, setAddresses, setContextsDebounced])

  return (
    <userContext.Provider
      value={{
        currentLocationCoords: locationData,
        setLocationCoords: setLocationData,
        isMapsLoaded: isLoaded,
        addresses: addresses,
        setAddresses: dispatch,
        wishlist: wishlist,
        modifyWishlist: modifyWishlist,
        wishlistStatus: wishlistStatus
      }}
    >
      {children}
    </userContext.Provider>
  )
}

export default UserContext
