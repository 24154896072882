'use client'

import { cn } from '@lib/utils'
import React, { HTMLAttributes, forwardRef } from 'react'
interface ProductPageSidePanelIndividualBoxProps extends HTMLAttributes<HTMLElement> {
  children: React.ReactNode
}

const ProductPageSidePanelIndividualBox = forwardRef<
  HTMLDivElement,
  ProductPageSidePanelIndividualBoxProps
>(({ children, ...props }, ref) => {
  return (
    <div
      className={cn(
        ' md:border border-neutral-200 rounded-sm md:px-4 bg-white pt-2',
        props.className
      )}
      ref={ref}
    >
      {children}
    </div>
  )
})

export default ProductPageSidePanelIndividualBox
