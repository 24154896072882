'use client'

import { ValueIdType } from '@components/types/CustomizationTypes'
import { Button } from '@components/ui/button'
import { Command, CommandGroup, CommandItem } from '@components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@components/ui/popover'
import { Check } from 'lucide-react'
import { useRouter, useSearchParams } from 'next/navigation'
import { FC, useCallback, useEffect, useState } from 'react'

interface DropdownSelectorProps {
  dropDownOptions: ValueIdType[]
  onSelection: (items: ValueIdType) => void
  value?: ValueIdType
  placeholder?: string
  showValueButtons?: boolean
  preselecteIndex?: number
  title: string
  defaultIndex?: number
  isCompulsory?: boolean
  isSubmitted?: boolean
}

const DropdownSelector: FC<DropdownSelectorProps> = ({
  onSelection,
  dropDownOptions,
  placeholder,
  preselecteIndex,
  title,
  defaultIndex,
  isCompulsory = false,
  isSubmitted = false
}) => {
  const router = useRouter()
  const preselected: number = preselecteIndex === undefined ? -1 : preselecteIndex
  const searchParams = useSearchParams()
  const [isSelected, setIsSelected] = useState(isCompulsory ? false : true)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [open, setOpen] = useState(false)
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState<ValueIdType | undefined>(
    dropDownOptions.length == 1 ? dropDownOptions[0] : undefined
  )
  const isDefaultValueSelected = searchParams.get(title)
  const handleSelectionForSingle = useCallback((selectedItem: ValueIdType) => {
    setCurrentSelectedOptions(() => selectedItem)
    // return [selectedItem]
  }, [])

  const paramsArray = Array.from(searchParams.entries())

  const currentParams = paramsArray
    .filter(([key, ele]) => key !== title)
    .map(([key, val]) => `${key}=${val}`)

  // useEffect(() => {
  //   if (preselecteIndex) {
  //     setCurrentSelectedOptions(preselecteIndex)
  //   }

  //   if (isDefaultValueSelected !== null) {
  //     const selected = dropDownOptions.find((ele) => ele.value == isDefaultValueSelected)
  //     if (selected) {
  //       setCurrentSelectedOptions(selected)
  //     }
  //   } else {
  //     setCurrentSelectedOptions(dropDownOptions[defaultIndex])
  //   }
  // }, [])

  // useEffect(() => {
  //   if (preselected > -1 && dropDownOptions) {
  //     setCurrentSelectedOptions(dropDownOptions[preselected])
  //   }
  // }, [dropDownOptions, preselected])

  useEffect(() => {
    if (currentSelectedOptions) {
      router.replace(
        `?${currentParams.join('&').replace(/ /g, '+')}&${title}=${currentSelectedOptions?.value}`,
        { scroll: false }
      )
      onSelection(currentSelectedOptions)
    }
  }, [currentSelectedOptions])

  // useEffect(() => {
  //   if (preselecteIndex && dropDownOptions) {
  //     setCurrentSelectedOptions(dropDownOptions[preselecteIndex])
  //   }
  // }, [dropDownOptions, preselecteIndex])

  useEffect(() => {
    if (isSubmitted) {
      if (!isSelected && isCompulsory) {
        setShowErrorMessage(true)
      } else {
        setShowErrorMessage(false)
      }
    }
  }, [isSubmitted, isSelected, isCompulsory])

  return (
    <div className="">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="none"
            role="combobox"
            aria-expanded={open}
            className={`w-full h-12 md:h-10 text-neutral-700 m-0 p-0 mobile_thin_medium md:label_small justify-between  px-4 md:px-0 rounded md:rounded-none shadow md:shadow-none border md:border-none ${
              showErrorMessage ? ' text-red-600 border-red-600' : 'default:'
            }`}
          >
            {currentSelectedOptions ? (
              <>{currentSelectedOptions.value}</>
            ) : preselected > -1 ? (
              dropDownOptions[preselected].value
            ) : placeholder ? (
              <>{placeholder}</>
            ) : (
              dropDownOptions[0].value
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className=" p-1 m-0 w-[92vw] md:w-[23vw]">
          <Command defaultValue={preselected > -1 ? dropDownOptions[preselected].id : undefined}>
            {/* <CommandInput placeholder="search item..." className="h-9" />
            <CommandEmpty>Not found</CommandEmpty> */}
            <CommandGroup
              className=" "
              defaultValue={preselected > -1 ? dropDownOptions[preselected].id : undefined}
            >
              {dropDownOptions.map((item) => (
                <CommandItem key={item.id} value={item.id} className="">
                  {currentSelectedOptions ? (
                    currentSelectedOptions.id === item.id ? (
                      <div className=" w-10 h-4">
                        <Check className=" stroke-primary-500" />
                      </div>
                    ) : (
                      <div className=" w-10 h-4" />
                    )
                  ) : null}
                  <div
                    className="h-full w-full cursor-pointer flex justify-between"
                    onClick={() => {
                      handleSelectionForSingle(item)
                      setOpen(false)
                      setIsSelected(true)
                    }}
                  >
                    {item.value}
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
      {/* {showValueButtons ? (
        <div className="flex pt-2 gap-2 flex-wrap">
          {currentSelectedOptions?.map((ele) => (
            <div key={ele.id} className="flex border p-1 rounded-sm">
              <div> {ele.value}</div>
              <X
                className="cursor-pointer"
                onClick={() => {
                  deselectFromList(ele.id)
                }}
              />
            </div>
          ))}
        </div>
      ) : null} */}
    </div>
  )
}

export default DropdownSelector
