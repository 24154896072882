import { Timeslots } from '@components/atoms/TimeSlotSelector'
import { UserCustomizationType } from '@components/context/CartContext'
import { ValueIdType } from '@components/types/CustomizationTypes'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export interface CartItem {
  productId: string
  quantity: number
  price: number
  userCustomizationData: UserCustomizationType
  name: string
  discountPerc: number
  imageIds: string[]
  originalPrice: number
  vendorIds: ValueIdType[]
  transportationCostPerKm: { day: number; night: number }
  cartId?: string | number
  distance: string
  nearestVendorId: string
  deliveryPrice: string
  addressId: string
  couponCode?: string
  isCouponApplied?: string
}

export interface CartContextProps {
  deliverySlot: string | undefined
  deliveryDate: string | undefined
  cartItems: CartItem[]
  updateCart: (data: CartAction) => void
  selectedAddressId: string | undefined
}

export type CartAction =
  | { method: 'add'; item: CartItem }
  | { method: 'remove'; item: CartItem }
  | { method: 'clear' }
  | { method: 'slotPick'; slot: Timeslots }
  | { method: 'date'; date: string }
  | { method: 'quantity'; item: CartItem; qty: number }
  | { method: 'set'; items: CartItem[] }
  | { method: 'setId'; productId: string; cartId: string | number }
  | {
      method: 'setNearestVendor'
      productId: string
      nearestVendorId: string
      distance: string
      deliveryPrice: string
    }
  | { method: 'setAddressId'; addressId: string }

const reducer = (state: CartContextProps, action: CartAction): CartContextProps => {
  let index
  const newCartItems = [...state.cartItems]
  switch (action.method) {
    case 'add':
      // Find item index
      index = state.cartItems.findIndex((item) => item.productId === action.item.productId)
      if (index !== -1) {
        // Update the item if it exists
        newCartItems[index] = {
          ...newCartItems[index],
          quantity: newCartItems[index].quantity + action.item.quantity
        }
      } else {
        // Add the new item if it doesn't exist
        newCartItems.push(action.item)
      }
      return { ...state, cartItems: newCartItems }
    case 'remove':
      return {
        ...state,
        cartItems: state.cartItems.filter((item) => item.productId !== action.item.productId)
      }
    case 'clear':
      return { ...state, cartItems: [] }

    case 'slotPick':
      return { ...state, deliverySlot: action.slot }
    case 'quantity':
      index = state.cartItems.findIndex((item) => item.productId === action.item.productId)
      newCartItems[index].quantity = action.qty
      return { ...state, cartItems: newCartItems }
    case 'date':
      return { ...state, deliveryDate: action.date }
    case 'set':
      return { ...state, cartItems: action.items }
    case 'setId':
      index = state.cartItems.findIndex((item) => item.productId === action.productId)
      if (index !== -1) {
        const tmp = [...state.cartItems]
        tmp[index].cartId = action.cartId
        return { ...state, cartItems: tmp }
      } else return state
    case 'setNearestVendor':
      index = state.cartItems.findIndex((item) => item.productId === action.productId)
      if (index !== -1) {
        const tmp = [...state.cartItems]
        tmp[index].distance = action.distance
        tmp[index].nearestVendorId = action.nearestVendorId
        tmp[index].deliveryPrice = action.deliveryPrice
        return { ...state, cartItems: tmp }
      } else return state
    case 'setAddressId':
      return { ...state, selectedAddressId: action.addressId }
    default:
      return state
  }
}

// Define a store for location data
const useCartStore = create<CartContextProps>()(
  persist(
    (set) => ({
      cartItems: [],
      deliverySlot: undefined,
      deliveryDate: undefined,
      selectedAddressId: undefined,
      updateCart: (action: CartAction) => {
        set((state) => reducer(state, action))
      }
    }),
    {
      name: 'cart-cache', // unique name for local storage key
      storage: createJSONStorage(() => sessionStorage) // specify local storage as the storage type
    }
  )
)

export default useCartStore
