'use client'
import { Libraries } from '@react-google-maps/api'
import { useContext } from 'react'
// import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

import { userContext } from '@components/context/userContext'
import { Skeleton } from '@components/ui/skeleton'
import { LatLng } from 'use-places-autocomplete'
import GoogleMapsPlaceSuggestions from './GoogleMapsPlaceSuggestions'

const googleMapsApi = process.env.MAPS_API as string

const polygonCoordds = [
  { lat: 13.22476, lng: 77.72916 },
  { lat: 13.0737, lng: 77.79691 },
  { lat: 12.98418, lng: 77.86563 },
  { lat: 12.85167, lng: 77.79388 },
  { lat: 12.80524, lng: 77.78495 },
  { lat: 12.70887, lng: 77.73354 },
  { lat: 12.72837, lng: 77.61736 },
  { lat: 12.83004, lng: 77.51188 },
  { lat: 12.89828, lng: 77.36957 },
  { lat: 12.98394, lng: 77.37294 },
  { lat: 13.11512, lng: 77.41416 },
  { lat: 13.21708, lng: 77.56213 }
]

export const library: Libraries | undefined = ['places', 'geometry']

export const isCoordinateInsidePolygon = (coordinate: LatLng) => {
  if (!window.google || !window.google.maps) {
    // Google Maps is not loaded or there's an error
    return false
  }

  const googleMaps = window.google.maps
  const polygon = new googleMaps.Polygon({ paths: polygonCoordds })
  console.log(googleMaps.geometry.poly.containsLocation(coordinate, polygon), 'inside polygon')
  return googleMaps.geometry.poly.containsLocation(coordinate, polygon)
}
const GoogleMapsLoader = () => {
  const locationContext = useContext(userContext)

  return (
    <div className=" label_small">
      {locationContext.isMapsLoaded ? (
        <>
          <GoogleMapsPlaceSuggestions />
          {/* <GoogleMap zoom={10} center={{ lat: 44, lng: -80 }} mapContainerClassName='map-container'></GoogleMap> */}
        </>
      ) : (
        <Skeleton className="h-[1.5em] w-full px-2"></Skeleton>
      )}
    </div>
  )
}

export default GoogleMapsLoader
