import * as React from 'react'
import Image from 'next/image'
import { cn } from '@/lib/utils'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  // placeHolderImg?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <div className="relative">
        {/* {props.placeHolderImg ? (
          <Image src={props.placeHolderImg} alt="placeholder" className="absolute left-3 top-3" />
        ) : undefined} */}
        <input
          type={type}
          className={cn(
            ' h-10 px-3 text-sm ring-offset-background border-0 rounded-sm file:border-0 file:bg-transparent file:text-sm file:font-medium  focus-visible:outline-none  focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            ' placeholder:text-xs placeholder:text-left md:placeholder:text-sm',
            className
          )}
          ref={ref}
          {...props}
        />
      </div>
    )
  }
)
Input.displayName = 'Input'

export { Input }
