import { getAllReviewsOfProduct } from '@app/actions'
import Stars from '@assets/addReview/starReviewCard.svg'
import { Separator } from '@components/ui/separator'
import { Skeleton } from '@components/ui/skeleton'
import Image from 'next/image'
import { FC } from 'react'
import { useQuery } from 'react-query'

const RatingPreviewCardUnderProductSidePanel: FC<{ productId?: string }> = ({ productId }) => {
  if (!productId) {
    return null // Return null if no productId is provided
  }

  const { data, isLoading } = useQuery(
    ['review', productId],
    () => getAllReviewsOfProduct(productId),
    {
      enabled: !!productId // only fetch if productId is available
    }
  )

  if (isLoading) {
    return <Skeleton className="h-8 w-full mb-2" />
  }

  if (data && data.reviews.length > 0) {
    return (
      <a href="#reviews">
        <div className=" bg-surface-50 flex md:gap-1 gap-2 items-center justify-center py-2 mb-2 px-3 rounded-md w-fit">
          <div className="flex justify-center items-center gap-1">
            {data.averageRating?.toFixed(1)}
            <Image src={Stars} width={20} height={20} alt="review" />
          </div>
          <Separator orientation="vertical" className="w-px bg-[#DDDDDD] min-h-5 h-fit" />
          <div className="text-[#666666] text-wrap">
            {data.reviews.length} rating{data.reviews.length > 1 ? 's' : ''}
          </div>
        </div>
      </a>
    )
  }

  return null // Return null if there are no reviews
}

export default RatingPreviewCardUnderProductSidePanel
