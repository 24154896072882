/* eslint-disable react-hooks/exhaustive-deps */
'use client'
import { Calendar } from '@components/ui/calendar'
import { Label } from '@components/ui/label'
import { Popover, PopoverContent, PopoverTrigger } from '@components/ui/popover'
import { Separator } from '@components/ui/separator'
import { format } from 'date-fns'
import { Pencil } from 'lucide-react'
import { FC, HTMLAttributes, useCallback, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import * as z from 'zod'
import TimeSlotSelector, { Timeslots } from './TimeSlotSelector'
import useCartStore from '@store/CartStore'
interface DeliveryDatePickerProps extends HTMLAttributes<HTMLElement> {
  startingDate: Date
  onDateSelect: (date: Date | undefined) => void
  showTimeSleots?: boolean
  className?: string
}

const formScheme = z.object({
  date: z.date({ required_error: 'select a date' })
})

const DeliveryDatePicker: FC<DeliveryDatePickerProps> = ({
  startingDate,
  onDateSelect,
  showTimeSleots = false,
  className
}) => {
  return (
    <div className={className}>
      <div className=" text-neutral-950 pb-2 md:pb-0">Delivery date & time</div>
      <DateTimeChoose startingDate={startingDate} />
    </div>
  )
}
const twoDaysFromNow = new Date()
const cutOffDate = new Date()
twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 3)
cutOffDate.setDate(cutOffDate.getDate() + 2)
const startingDateIfNotGiven = twoDaysFromNow

export const DateTimeChoose: FC<{ startingDate?: Date }> = ({ startingDate }) => {
  const [open, setOpen] = useState(false)
  const cart = useCartStore()

  const startDate = startingDate ? startingDate : startingDateIfNotGiven

  const handleDateChange = useCallback((date: Date | undefined) => {
    if (date) cart.updateCart({ method: 'date', date: date?.toDateString() })
    setOpen(false)
  }, [])

  const handleTimeChange = useCallback((slot: Timeslots) => {
    cart.updateCart({ method: 'slotPick', slot: slot })
  }, [])

  const ref = useOnclickOutside(() => {
    setOpen(false)
  })

  return (
    <Popover open={open}>
      <PopoverTrigger asChild>
        <div onClick={() => setOpen((prev) => !prev)}>
          {cart?.deliveryDate ? (
            <div className="flex items-center flex-grow w-full cursor-pointer shadow-sm md:shadow-none border md:border-none rounded md:rounded-none">
              <div className="flex h-12 md:h-10 items-center px-4 md:px-0 w-full justify-between">
                <div className=" flex">
                  <div className=" label_small text-primary-500 whitespace-nowrap">
                    {format(new Date(cart?.deliveryDate), 'PPP')}
                  </div>
                  <Separator orientation="vertical" className=" mx-2 h-6" />
                  <div className=" mobile_thin_medium md:label_small text-primary-500 whitespace-nowrap">
                    {cart.deliverySlot ? cart.deliverySlot : 'select a slot'}
                  </div>
                </div>

                <Pencil className=" ml-2" color="rgb(232 0 113)" strokeWidth={1} />
              </div>
            </div>
          ) : (
            <div className=" label_small pl-4 md:pl-1 rounded-sm py-3 shadow md:shadow-none md:py-2.5 border md:border-none ">
              <span className=" text-primary-500">
                {format(startingDate ? startingDate : startingDateIfNotGiven, 'PPP')}
              </span>
              <span className=" text-[#DADFE6]"> |</span>{' '}
              <span className=" text-blue cursor-pointer">select slot</span>
            </div>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 border rounded-sm">
        <div ref={ref}>
          <div className=" w-full flex justify-center items-center p-2 pt-4">
            <Label>Select your event date and time</Label>
          </div>
          <Calendar
            mode="single"
            selected={cart!.deliveryDate ? new Date(cart!.deliveryDate) : startDate}
            disabled={(date) => date < cutOffDate}
            initialFocus={false}
            onSelect={handleDateChange}
          />
          <div className="flex justify-between items-center p-2  rounded-b-sm z-10 bg-primary-100">
            <Label>Choose delivery time slot</Label>
            <TimeSlotSelector onSelect={handleTimeChange} />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default DeliveryDatePicker
