'use client'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select'
import useCartStore from '@store/CartStore'
import { FC } from 'react'

interface TimeSlotSelector {
  onSelect: (timeSlot: Timeslots) => void
}
export type Timeslots = '6am - 10am' | '10am - 2pm' | '2pm - 6pm' | '6pm - 9pm' | '9pm - 12pm'
const Timeslots = ['6am - 10am', '10am - 2pm', '2pm - 6pm', '6pm - 9pm', '9pm - 12pm']
const TimeSlotSelector: FC<TimeSlotSelector> = ({ onSelect }) => {
  const cart = useCartStore()

  return (
    <Select onValueChange={(e: Timeslots) => onSelect(e)}>
      <SelectTrigger className="w-[150px]">
        <SelectValue placeholder={cart.deliverySlot ? cart.deliverySlot : 'select a timeslot'} />
      </SelectTrigger>
      <SelectContent>
        {Timeslots.map((ele, ind) => (
          <SelectItem key={ind} value={ele}>
            <div className=" label_medium">{ele}</div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default TimeSlotSelector
