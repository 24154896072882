import { Input } from '@components/ui/input';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@components/ui/tooltip';
import { FC, useState } from 'react';

interface UserCustomizationInputProps {
  placeholder: string;
  onChange: (currentVal: string) => void;
  maxCharacters: number;
  title: string;
}

const UserCustomizationInput: FC<UserCustomizationInputProps> = ({
  placeholder,
  maxCharacters,
  onChange,
  title
}) => {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false); // State to manage focus

  return (
    <div className="flex flex-col">
      <TooltipProvider>
        <Tooltip open={isFocused && value.length !== 0} >
          <TooltipTrigger asChild>
            <Input
              placeholder={placeholder}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className="md:label_small placeholder:text-16 mobile_thin_medium md:border-none m-0 w-full text-ellipsis px-4 bg-transparent md:p-0 focus-visible:ring-0 h-12 md:h-10"
              maxLength={maxCharacters}
            />
          </TooltipTrigger>
          <TooltipContent className="max-w-xs md:max-w-md">
            <div className='w-full'>
              {value.length === maxCharacters ? (
                <span className="text-red-500">Sorry! Your input exceeds the max character limit.</span>
              ) : value.length > 0 ? (
                <span className="break-words mobile_thin_small text-green-700 text-center">
                  "<i className="mobile_condensed_small flex-wrap">{value}&nbsp;</i>" will be used for {title}.
                </span>
              ) : null}
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export default UserCustomizationInput;
