import { OrderDetails } from '@app/(auth)/orders/status/[paymentId]/_components/ServerOrderDetails'
import { CouponDiscount, CouponType } from '@components/molecules/CouponSelector'

import {
  DropdownSelectionProps,
  dependecyProps,
  stepperChoiceProps
} from '@components/types/CustomizationTypes'
import { PaymentApi } from '@components/types/PaymentConfirmation'
import { clsx, type ClassValue } from 'clsx'
import { createHash, createHmac } from 'crypto'
import { useSearchParams } from 'next/navigation'
import { NextResponse } from 'next/server'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const convertToNearestNine = (val: number) => {
  return Math.round(val)
  return Math.round(Math.ceil(val / 10) * 10 - 1)
}

const baseUrl = process.env.API_BASE_URL

export async function checkIfOrderIdExist(id: string) {
  try {
    const url = baseUrl + `orders/v1/admin/isOrderIdExists/${id}`
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })

    if (!res.ok) {
      // I am sending false because in that case, we can log this response and
      // sort it out later
      return { exists: false }
    }
    const response: { exists: boolean } = await res.json()
    console.log(response, '---------------------------')
    return response
  } catch (error) {
    return { exists: false }
  }
}

const url = process.env.API_BASE_URL_CART

interface CartItems {
  cartid: string
  userId: string
  productId: string
  quantity: number
  unitPrice: number
  totalPrice: number
  deliveryprice: number
  deliverydate: string
  timeslot: string
  discountedprice: string
  transportationcostperkm: string
  vendorids: string
  productname: string
  imageids: string
  userCustomizationData: string
  nearestVendor: string
  distance: string
  addressId: string
  couponCode?: string
}

export const getCartItems = async (user: string) => {
  try {
    const res = await fetch(`${url}/cart/v1/admin/getCartItems`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({ userid: user }),
      cache: 'no-cache'
    })
    if (!res.ok) {
      throw new Error('failed to fetch')
    }

    const response: { cartitems: CartItems[] } = await res.json()
    // console.log(response.cartitems)
    return response.cartitems
  } catch (error) {
    throw new Error('failed to fetch')
  }
}

export const calcCartPrice = (cartItems: CartItems[]) => {
  let price = 0
  cartItems.map((item) => {
    const productPrice = item.totalPrice
    price += productPrice
  })
  return price
}

export const calCartPriceWithCoupon = async (cartItems: CartItems[]) => {
  const couponCode = cartItems[0].couponCode
  const totalPriceBefore = calcCartPrice(cartItems)
  if (couponCode !== '') {
    const getCouponDiscount = await fetch(
      `https://www.eventshoppe.co.in:9022/coupon/calculateDiscount/${couponCode}?billingAmount=${totalPriceBefore}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }
    )
    // console.log(getCouponDiscount)
    const response: CouponDiscount = await getCouponDiscount.json()
    if (response.status !== 'FAILURE') {
      return response.discountedBillingAmount
    }
  }

  return totalPriceBefore
}

const findPriceWithDropdowns = (
  basePrice: number,
  dropdownOptions: DropdownSelectionProps[] = [],
  primaryDropdownMultiplicationFactorDets:
    | false
    | { currentCoefficientFactor: number; cost: number } = false,
  stepperMultiplicationFactorDets: false | { currentChoice: stepperChoiceProps } = false,
  stepperCount: number | undefined = undefined
) => {
  let tmpPrice = basePrice

  dropdownOptions.map((ele) => {
    if (ele && ele.dependency) {
      const dep = JSON.parse(ele.dependency) as dependecyProps[]
      //taking first element since it's an array
      if (dep[0].isDropdown && primaryDropdownMultiplicationFactorDets) {
        const extra =
          parseFloat(ele.choice.cost) *
          primaryDropdownMultiplicationFactorDets.currentCoefficientFactor
        //adding all the variables that comes when dependency is dropdown
        tmpPrice += extra
        // console.log(tmpPrice + extra, 'depend on dropdown')
      } else if (!dep[0].isDropdown && stepperMultiplicationFactorDets) {
        if (ele.isPrimary) {
          // console.log('primary', basePrice * stepperCount!!)
        } else {
          const extra =
            parseFloat(ele.choice.cost) *
            parseFloat(stepperMultiplicationFactorDets.currentChoice.secondaryCoefficient)
          tmpPrice += extra
        }
      }
    } else if (ele && !ele.dependency) {
      const extra = ele.choice.cost
      tmpPrice += parseFloat(extra)
    }
  })

  if (stepperCount) {
    tmpPrice *= stepperCount
  }

  return tmpPrice
}

export const getAnOrderId = async () => {
  try {
    const url = baseUrl + 'orders/v1/admin/createOrderID'
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })

    if (!res.ok) {
      throw new Error('failed')
    }
    const response: { orderId: string } = await res.json()
    return response.orderId
  } catch (error) {
    // throw new Error('failed')
    throw new Error('failed')
  }
}

export const createMessageForKafka = (props: KafkaMessage): KafkaProps => {
  const tmp = {
    orderKey: {
      orderId: props.orderId,
      partitionNo: 1
    },
    products: props.products,
    userId: props.userId,
    addressId: props.addressId,
    paymentStatus: props.paymentStatus,
    orderStatus: props.orderStatus,
    paymentMode: props.paymentMode,
    orderDateTime: Date.now().toString(),
    calYear: new Date().getFullYear().toString(),
    totalOrderPrice: props.totalOrderPrice,
    couponAppliedPrice: props.couponAppliedPrice,
    couponcode: props.couponcode,
    razorpay_payment_id: props.razorpay_payment_id,
    razorpay_order_id: props.razorpay_order_id,
    razorpay_signature: props.razorpay_signature
  }

  // console.log(tmp)
  return tmp
}

const message = {
  orderKey: {
    orderId: 'arun',
    partitionNo: 1
  },
  userId: 'user123',
  productId: 'product789',
  quantity: 2,
  unitPrice: 25.99,
  totalPrice: 51.98,
  deliveryPrice: 5.0,
  userCustomization: 'Special instructions',
  deliveryDate: '2023-12-31',
  timeSlot: 'Morning',
  addressId: 'address456',
  paymentStatus: 'Pending',
  orderStatus: 'Processing',
  paymentMode: 'Credit Card',
  orderDateTime: '2023-12-09T14:30:00',
  vendorId: 'vendor789',
  distance: 10.5,
  calYear: 2023
}

interface KafkaProducts {
  productId: string
  quantity: number
  unitPrice: number
  userCustomization: string
  deliveryPrice: number
  vendorId: string
  distance: string
  totalPrice: number
  deliveryDate: string
  timeSlot: string
}
interface KafkaMessage {
  userId: string
  products: KafkaProducts[]
  addressId: string
  paymentStatus: string
  orderStatus: string
  paymentMode: string
  totalOrderPrice: string
  orderId: string
  couponAppliedPrice: string
  couponcode?: string
  razorpay_payment_id?: string
  razorpay_order_id?: string
  razorpay_signature?: string
}

interface KafkaProps {
  orderKey: {
    orderId: string
    partitionNo: number
  }
  products: KafkaProducts[]
  userId: string
  addressId: string
  paymentStatus: string
  orderStatus: string
  paymentMode?: string
  orderDateTime: string
  calYear: string
  totalOrderPrice: string
  couponAppliedPrice: string
  couponcode?: string
}

export const callKafka = async (message: KafkaProps) => {
  console.log(message, 'kafka message')
  try {
    fetch(
      'https://pkc-n3603.us-central1.gcp.confluent.cloud:443/kafka/v3/clusters/lkc-nkrqmv/topics/orders/records',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'Basic WDRQSldQS0pLWENQUFM3Vjo1QkVNYWhCV2ljV2hjL1hGbnNHSFplY3FhcG1QOFFBNTA4Q0krU1gvM0JlYkdkTndPUTQxa2lISDJLOHNqVGhT'
        },
        body: JSON.stringify({
          value: { type: 'JSON', data: message }
        }),
        cache: 'no-cache'
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json() // Or .text() if the response is text, not JSON
        }
        throw new Error('Network response was not ok.')
      })
      .then((data) => console.log(data))
    // return NextResponse.json('working')
  } catch (error) {
    console.log(error + 'kafka failed')
    return NextResponse.json('not working')
  }
}

export function startPaymentStatusChecksForDelay(checkFnc: () => void) {
  // Initial check after 20-25 seconds
  setTimeout(checkFnc, 22.5 * 1000) // Average of 20 and 25 seconds for the first check

  // Every 3 seconds for the next 30 seconds
  setTimeout(() => {
    const interval = setInterval(checkFnc, 3 * 1000)
    setTimeout(() => clearInterval(interval), 30 * 1000)
  }, 22.5 * 1000)

  // Every 6 seconds for the next 60 seconds
  setTimeout(() => {
    const interval = setInterval(checkFnc, 6 * 1000)
    setTimeout(() => clearInterval(interval), 60 * 1000)
  }, (22.5 + 30) * 1000)

  // Every 10 seconds for the next 60 seconds
  setTimeout(() => {
    const interval = setInterval(checkFnc, 10 * 1000)
    setTimeout(() => clearInterval(interval), 60 * 1000)
  }, (22.5 + 30 + 60) * 1000)

  // Every 30 seconds for the next 60 seconds
  setTimeout(() => {
    const interval = setInterval(checkFnc, 30 * 1000)
    setTimeout(() => clearInterval(interval), 60 * 1000)
  }, (22.5 + 30 + 60 + 60) * 1000)

  // Every 1 minute until 20 minutes timeout
  const startTime = Date.now()
  const endTime = startTime + 20 * 60 * 1000 // 20 minutes from start
  function scheduleFinalChecks() {
    const interval = setInterval(() => {
      if (Date.now() >= endTime) {
        clearInterval(interval)
        console.log('Payment status check timeout reached.')
      } else {
        checkFnc()
      }
    }, 60 * 1000)

    // Adjusting the initial delay for the first check in this phase to match the schedule
    const initialDelay = endTime - Date.now() - 18 * 60 * 1000 // Time until the last phase
    setTimeout(() => checkFnc(), initialDelay)
  }

  // Schedule the final phase checks to start after the last 60-second phase
  setTimeout(scheduleFinalChecks, (22.5 + 30 + 60 + 60 + 60) * 1000)
}

export const today = () => {
  const toay = new Date()
  return `${toay.getDate()}-${toay.getMonth() + 1}-${toay.getFullYear()}`
}

export const createQueryString = (key: string, val: string) => {
  return `${key}=${val}`
}

export const getEligibleCoupons = async (amount: number) => {
  const res = await fetch(
    `https://www.eventshoppe.co.in:9022/coupon/eligibleCoupons?billingAmount=${amount}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }
  )

  if (!res.ok) {
    throw new Error('failed to fetch coupons')
  }

  const response: { coupons: CouponType[] } = await res.json()

  return response.coupons
}

const saltkey = process.env.SALT_KEY
export const phonePayPaymentCheck = async (transactionId: string) => {
  const string = `/pg/v1/status/EVENTONLINE/${transactionId}` + saltkey

  const sha256 = createHash('sha256').update(string).digest('hex')
  const xVerify = sha256 + '###' + '1'
  const merchantIdUnique = 'EVENTONLINE'

  const res = await fetch(
    `https://api.phonepe.com/apis/hermes/pg/v1/status/EVENTONLINE/${transactionId}`,
    {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        'X-VERIFY': xVerify,
        'X-MERCHANT-ID': merchantIdUnique
      }
    }
  )

  const response: PaymentApi = await res.json()
  return response
}

export const getOrderDetailsFn = async (userId: string, orderId: string) => {
  try {
    const res = await fetch(
      `https://www.eventshoppe.co.in:9022/orders/v1/admin/getAllProductWiseOrderDetails/${orderId}/${userId}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
      }
    )
    const response: OrderDetails = await res.json()
    const paymentDetails = await phonePayPaymentCheck(orderId)
    return { ...response, paymentMethod: paymentDetails.data?.paymentInstrument }
  } catch (error) {
    // redirect(`/orders/status/${orderId}`)
    console.log(error, ' fetched with')
    throw new Error('failed to fetch order details ')
  }
}

export const clearUserCart = async (userId: string) => {
  const cartItems = await getCartItems(userId)

  const cartArr = cartItems.map((ele) => ({ productId: ele.productId, cartId: ele.cartid }))

  Promise.all(
    cartArr.map(async (prod) => {
      await fetch('https://www.eventshoppe.co.in:9026/cart/v1/admin/removeFromCart', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        body: JSON.stringify({ ...prod, userId })
      })
    })
  )

  return true
}

export const createHashFromString = (string: string) =>
  createHash('sha256').update(string).digest('hex')

export function getFbClientId() {
  const result = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie)
  if (!(result && result[1])) {
    return null
  }
  return result[1]
}

export function getCookie(name: string) {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')
  if (parts.length === 2) return parts.pop()!.split(';').shift()
}

export const createAddReviewToken = (orderId: string) => {
  const secretKey = process.env.REVIEW_HASH_SECRET
  if (secretKey) {
    const token = createHmac('sha256', secretKey).update(orderId).digest('hex')
    return token
  }
  throw new Error('missing secretKey')
}

export const checkIfValidReviewToken = (token: string, orderId: string) => {
  const tokenGenerated = createAddReviewToken(orderId)
  return token === tokenGenerated ? true : false
}

export const safeParseJson = (jsonString: string) => {
  try {
    return JSON.parse(jsonString)
  } catch (error) {
    throw new Error('not a valid JSON')
  }
}
