'use client'
import useCartStore, { CartAction, CartContextProps, CartItem } from '@store/CartStore'
import { useSession } from 'next-auth/react'
import { ReactNode, createContext, useEffect, useState } from 'react'

interface CartContextType {
  cartItems: CartItem[]
  deliverySlot: string | undefined
  updateCart: (action: CartAction) => void
  deliveryDate: string | undefined
  selectedAddressId: string | undefined
}

interface CartItemsFromDb {
  id: number
  userId: string
  productId: string
  quantity: number
  unitPrice: number
  totalPrice: number
  deliveryprice: string
  deliverydate: string
  timeslot: string
  discountedprice: string
  transportationcostperkm: string
  vendorids: string
  productname: string
  imageids: string
  userCustomizationData: string
  cartid: string | number
  distance: string
  nearestVendor: string
  addressId: string
  couponCode: string
  isCouponApplied: string
}

export interface UserCustomizationType {
  dropDowns?: {
    choice: { choice: string; cost: string }
    isPrimary: boolean
    title: string
  }[]
  textInput?: {
    title: string
    content: string
  }[]
  stepper?: {
    title: string
    count: number
  }
}

interface AddtoCart {
  userId: string
  productId: string
  quantity: number
  price: number
  userCustomizationData: UserCustomizationType
  deliveryprice: number
  deliverydate?: string
  timeslot?: string
  discountedprice: string
  transportationcostperkm: string
  vendorids: string
  productname: string
  imageids: string
  distance: string
  nearestVendor: string
  addressId?: string
  couponCode?: string
}

export const addToCartFnc = async (cartItem: AddtoCart) => {
  try {
    const res = await fetch('/api/cart/addToCart', {
      method: 'POST',
      body: JSON.stringify({
        ...cartItem,
        userCustomizationData: JSON.stringify(cartItem.userCustomizationData)
      })
    })

    if (!res.ok) {
      throw new Error('failed to add')
    }

    const response: { cartid: string } = await res.json()
    return response.cartid
  } catch (error) {
    throw new Error('failed to add')
  }
}

interface GetCartFnProps {
  userId: string
  localcartItems: CartItem[]
  deliveryDate?: string
  deliverySlot?: string
}

export const getDbCart = async (userId: string) => {
  try {
    const res = await fetch('/api/cart/getCartItems', {
      method: 'POST',
      body: JSON.stringify({ userid: userId })
    })
    // console.log('response ', props)
    if (!res.ok) {
      // console.error('failed to fetch')
    }

    const response: { cartitems: CartItemsFromDb[] } = await res.json()
    return response
  } catch (error) {
    throw new Error('failed to fetch')
  }
}

export const getCartItems = async (props: GetCartFnProps) => {
  try {
    const response = await getDbCart(props.userId)
    // console.log({ response }, 'response from getcart')

    //when db cart is empty and local cart is there
    if (response.cartitems.length === 0) {
      if (props.localcartItems.length > 0) {
        const promises = Promise.all(
          props.localcartItems.map((item) => {
            const cartItem: AddtoCart = {
              deliverydate: props.deliveryDate,
              deliveryprice: 0,
              discountedprice: String(item.discountPerc),
              imageids: item.imageIds.join(','),
              productId: item.productId,
              productname: item.name,
              quantity: item.quantity,
              timeslot: props.deliverySlot,
              transportationcostperkm: JSON.stringify(item.transportationCostPerKm),
              userId: props.userId,
              vendorids: JSON.stringify(item.vendorIds),
              price: item.price,
              userCustomizationData: item.userCustomizationData,
              distance: item.distance,
              nearestVendor: item.nearestVendorId
            }
            return addToCartFnc(cartItem)
          })
        )
      }
    }

    return response.cartitems
  } catch (error) {
    // throw new Error('failed to fetch')
    // console.error('failed to fetch')
  }
}

export const CartContext = createContext<CartContextType | undefined>(undefined)

export const synCart = async (userId: string, store: CartContextProps) => {
  const res = getCartItems({
    userId: userId,
    localcartItems: store.cartItems,
    deliveryDate: store.deliveryDate,
    deliverySlot: store.deliverySlot
  }).then((dbCart) => {
    // setDbCart(dbCart)
    // console.log({ dbCart })
    if (!dbCart) return
    const cartItemsConverted: CartItem[] = dbCart.map((item) => ({
      discountPerc: parseFloat(item.discountedprice),
      imageIds: item.imageids.split(','),
      name: item.productname,
      originalPrice: item.totalPrice,
      productId: item.productId,
      quantity: item.quantity,
      transportationCostPerKm: JSON.parse(item.transportationcostperkm),
      userCustomizationData: JSON.parse(item.userCustomizationData),
      vendorIds: JSON.parse(item.vendorids),
      cartId: item.cartid,
      deliveryPrice: item.deliveryprice,
      distance: item.distance,
      nearestVendorId: item.nearestVendor,
      price: item.unitPrice,
      addressId: item.addressId
    }))

    if (store) {
      if (!store.deliverySlot) {
        store.updateCart({ method: 'slotPick', slot: '10am - 2pm' })
      }
      if (!store.deliveryDate) {
        const twoDaysFromNow = new Date()
        twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 3)
        const minDate = new Date(twoDaysFromNow)
        store.updateCart({ method: 'date', date: minDate.toString() })
      }

      if (store.cartItems.length === 0) {
        // when db cart is not empty and local cart is empty
        store.updateCart({
          method: 'set',
          items: cartItemsConverted
        })
      } else {
        // when db cart and local cart are not empty, adding both

        // here I am filtering cartcontext items that are not ther in dbcart

        const presentInDbNotInLocal = cartItemsConverted.filter(
          (cartItem) => !store.cartItems.some((dbItem) => dbItem.productId == cartItem.productId)
        )

        const presentInLocalNotInDb = store.cartItems.filter(
          (cartItem) => !cartItemsConverted.some((dbItem) => dbItem.productId == cartItem.productId)
        )

        const presentInBothButWithoutCartId = cartItemsConverted.filter((dbItem) =>
          store.cartItems.some(
            (localItem) =>
              localItem.productId == dbItem.productId &&
              (dbItem.cartId !== localItem.cartId || !localItem.cartId)
          )
        )

        // updating cartIds locally
        presentInBothButWithoutCartId.map((ele) => {
          store.updateCart({ method: 'setId', cartId: ele.cartId!!, productId: ele.productId })
        })

        //syncing the local
        presentInDbNotInLocal.map((ele) => {
          store.updateCart({ method: 'add', item: ele })
        })

        // syncing the db
        const addToCartPromises = Promise.all(
          presentInLocalNotInDb.map((ele) => {
            return addToCartFnc({
              deliveryprice: 0,
              discountedprice: String(ele.discountPerc),
              imageids: ele.imageIds.join(','),
              price: ele.price,
              productId: ele.productId,
              productname: ele.name,
              quantity: ele.quantity,
              transportationcostperkm: JSON.stringify(ele.transportationCostPerKm),
              userCustomizationData: ele.userCustomizationData,
              userId: userId,
              vendorids: JSON.stringify(ele.vendorIds),
              deliverydate: '',
              timeslot: '',
              distance: ele.distance,
              nearestVendor: ele.nearestVendorId
            })
          })
        )
      }
    }
  })
}
export const CartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const store = useCartStore()
  const [dbCart, setDbCart] = useState<CartItemsFromDb[] | undefined>(undefined)
  const { data: session } = useSession()
  const contextValue = {
    cartItems: store.cartItems,
    deliverySlot: store.deliverySlot,
    updateCart: store.updateCart,
    deliveryDate: store.deliveryDate,
    selectedAddressId: store.selectedAddressId
  }

  useEffect(() => {
    // if the user selected date is before the 2 days
    // from now, setting the date to 2 days from now
    const twoDaysFromNow = new Date()
    twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 3)
    const userSelectedDate = store.deliveryDate
    if (session && userSelectedDate) {
      const minDate = new Date(twoDaysFromNow)
      const userDate = new Date(userSelectedDate)

      if (minDate > userDate) {
        store.updateCart({ method: 'date', date: minDate.toDateString() })
      }
    }
  }, [session])

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
}
