import { ProductPriceCalc } from '@app/api/products/price/route'
import { useCallback } from 'react'

const convertToNearestNine = (val: number) => {
  return Math.round(val)
  // return Math.round(Math.ceil(val / 10) * 10 - 1)
}

const ProductPriceUtils = () => {
  const getPriceForProductCards = useCallback(
    (basePrice: number, commision: number, discountPercentage: number) => {
      const afterCommision = basePrice + (basePrice * commision) / 100
      const priceAfterDiscount = (afterCommision * (100 - discountPercentage)) / 100
      const discountPriceWith9 = convertToNearestNine(priceAfterDiscount)
      return { priceBeforeDiscount: priceAfterDiscount, priceAfterDiscount: discountPriceWith9 }
    },
    []
  )

  const getPriceWithDropDowns = useCallback(async (props: ProductPriceCalc) => {
    try {
      const res = await fetch('/api/products/price', {
        method: 'POST',
        body: JSON.stringify(props)
      })
      if (!res.ok) {
        throw new Error('check body of price cal')
      }
      const price: number = await res.json()
      return price
    } catch (error) {
      throw new Error('check body of price cal')
    }
  }, [])
  return { getPriceForProductCards, getPriceWithDropDowns }
}

export default ProductPriceUtils
