'use client'
import { DropdownSelectionProps, customizationProps } from '@components/types/CustomizationTypes'
import { DbProductProps } from '@lib/GetProducts'
import React, { ReactNode, createContext, useCallback, useState } from 'react'

interface ProductContextType {
  product: DbProductProps
  price: number
}
const convertToNearestNine = (val: number) => {
  return Math.round(val)
  // return Math.round(Math.ceil(val / 10) * 10 - 1)
}

const calcPriceWithDiscounts = (price: number, discountPerc: number, commision: number) => {
  //TODO: this tag might change in the future - it should be discount perc in the DB

  const afterCommision = price + (price * commision) / 100
  const priceAfterDiscount = (afterCommision * (100 - discountPerc)) / 100
  return convertToNearestNine(priceAfterDiscount)
}
export const ProductDetails = createContext<ProductContextType | undefined>(undefined)
const ProductDetailsContext: React.FC<{ children: ReactNode; productDets: DbProductProps }> = ({
  children,
  productDets
}) => {
  const customizableData: customizationProps | undefined = productDets.userCustomization
    ? JSON.parse(productDets.userCustomization)
    : undefined
  const [price, setPrice] = useState(
    calcPriceWithDiscounts(
      parseFloat(productDets.actualPrice),
      parseFloat(productDets.discountedPrice),
      parseFloat(productDets.commission)
    )
  )

  const selectCustomization = useCallback((dropDownoptionsSelected: DropdownSelectionProps[]) => {
    // const primaryDropdownMultiplicationFactorDets =
    // dropDownoptionsSelected.map((ele) => {
    //   if (ele && ele.dependency) {
    //     const dep = JSON.parse(ele.dependency) as dependecyProps[]
    //     //taking first element since it's an array
    //     if (dep[0].isDropdown && primaryDropdownMultiplicationFactorDets) {
    //       const extra =
    //         parseFloat(ele.choice.cost) *
    //         primaryDropdownMultiplicationFactorDets.currentCoefficientFactor
    //       //adding all the variables that comes when dependency is dropdown
    //       setTmpPrice((prev) => prev + extra)
    //       // console.log(tmpPrice + extra, 'depend on dropdown')
    //     } else if (!dep[0].isDropdown && stepperMultiplicationFactorDets) {
    //       if (ele.isPrimary) {
    //         // console.log('primary', basePrice * stepperCount!!)
    //       } else {
    //         const extra =
    //           parseFloat(ele.choice.cost) *
    //           parseFloat(stepperMultiplicationFactorDets.currentChoice.secondaryCoefficient)
    //         setTmpPrice((prev) => prev + extra)
    //       }
    //     }
    //   } else if (ele && !ele.dependency) {
    //     const extra = ele.choice.cost
    //     setTmpPrice((prev) => prev + parseFloat(extra))
    //   }
    // })
  }, [])

  return (
    <ProductDetails.Provider value={{ product: productDets, price: price }}>
      {children}
    </ProductDetails.Provider>
  )
}

export default ProductDetailsContext
