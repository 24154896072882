import { useCallback } from 'react'
interface TransportationDetails {
  transportationid: string
  transportmedium: string
  costperkmday: number
  costperkmnight: number
  status: 'SUCCESS' | 'Transportation doesnot exist'
  statusCode: '0' | '115'
}
const GetTransportationDetails = () => {
  const getTransportationDetails = useCallback(async (id: string) => {
    try {
      const res = await fetch('/api/transportation', {
        method: 'POST',
        body: JSON.stringify({ transportationid: id })
      })
      if (!res.ok) {
        return null
      }
      const response: TransportationDetails = await res.json()
      return response
    } catch (error) {
      return null
    }
  }, [])
  return { getTransportationDetaisl: getTransportationDetails }
}

export default GetTransportationDetails
