import { LocationProps } from '@components/context/userContext'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface LocationStoreProps {
  locationData: LocationProps | undefined
  setLocationData: (data: LocationProps | undefined) => void
}
// Define a store for location data
const useLocationStore = create<LocationStoreProps>()(
  persist(
    (set) => ({
      locationData: undefined,
      setLocationData: (data) => set({ locationData: data })
    }),
    {
      name: 'location-storage', // unique name for local storage key
      storage: createJSONStorage(() => localStorage) // specify local storage as the storage type
    }
  )
)

export default useLocationStore
