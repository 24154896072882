'use client'
import {
  DropdownSelectionProps,
  primaryDropdownCustomizationProps
} from '@components/types/CustomizationTypes'
import { FC, useEffect, useRef, useState } from 'react'
import DropdownSelector from '../DropdownSelector'
import ProductPageSidePanelIndividualBox from '../ProductPageSidePanelIndividualBox'

interface PrimaryDropdownProps {
  data: primaryDropdownCustomizationProps
  onSelection: (val: DropdownSelectionProps) => void
  isCompulsory?: boolean
  isSubmitted?: boolean
}

const PrimaryDropdown: FC<PrimaryDropdownProps> = ({
  data,
  onSelection,
  isCompulsory = false,
  isSubmitted = false
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isSelected, setIsSelected] = useState(false)
  useEffect(() => {
    if (isSubmitted && !isSelected) {
      ref.current?.classList.add('animate-shake')
      // ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })

      const timer = setTimeout(() => {
        ref.current?.classList.remove('animate-shake')
      }, 1000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [isSelected, isSubmitted])
  return (
    <ProductPageSidePanelIndividualBox ref={ref}>
      <div className=" mb-2 md:mb-0">{data.title}</div>
      <DropdownSelector
        dropDownOptions={data.choices.map((choiceString, ind) => ({
          id: ind.toString(),
          value: choiceString.choice
        }))}
        onSelection={(val) => {
          const selected = data.choices.filter((opt) => opt.choice === val.value)
          onSelection({
            choice: selected[0],
            dependency: data.dependency,
            isPrimary: data.coefficientDependency === '{"id":"0","value":"Primary"}',
            title: data.title
          })
          setIsSelected(true)
        }}
        placeholder={data.placeholder}
        title={data.title}
        defaultIndex={data.choices.findIndex((ele) => ele.isDefault)}
        preselecteIndex={data.choices.length === 1 ? 0 : isCompulsory ? undefined : 0}
        // preselecteIndex={0}
        isCompulsory={isCompulsory}
        isSubmitted={isSubmitted}
      />
    </ProductPageSidePanelIndividualBox>
  )
}

export default PrimaryDropdown
