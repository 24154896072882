import { cn } from '@lib/utils'
import { FC, HTMLAttributes } from 'react'

interface AppDividerProps extends HTMLAttributes<HTMLDivElement> {
  demi?: boolean
  short?: boolean
  tall?: boolean
  grande?: boolean
  venti?: boolean
}

const AppDivider: FC<AppDividerProps> = ({ demi, short, tall, grande, venti, ...props }) => {
  // Map props to height classes
  const heightClass = demi
    ? 'h-2'
    : short
    ? 'h-4'
    : tall
    ? 'h-8'
    : grande
    ? 'h-12'
    : venti
    ? 'h-16'
    : 'h-8'

  return <div {...props} className={cn(`mx-10 ${heightClass}`, props.className)} />
}

export default AppDivider
