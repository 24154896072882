'use client'
import { ComponentProps, FC, useContext, useEffect } from 'react'
import { userContext } from '@components/context/userContext'
import usePlacesAutocomplete from 'use-places-autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'
import LocateMeButton from './LocateMeBtn'
import GoogleMapsLoader from './GoogleMapsLoader'

interface PincodeInputProps {}

const PincodeInput: FC<ComponentProps<'div'>> = ({...props}) => {
  const {
    ready,
    suggestions: { status, data },
    value,
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete()

  const locationContext = useContext(userContext)

  useEffect(() => {
    locationContext.currentLocationCoords?.displayAdress
  }, [locationContext])

  // const handleSelect = async (address: string) => {
  //   setValue(address, false)
  //   clearSuggestions()
  //   // setSelected(address)
  //   const result = await getGeocode({ address })
  //   const { lat, lng } = await getLatLng(result[0])
  //   locationContext.setLocationCoords({ displayAdress: address, latitude: lat, longitude: lng })
  // }

  const ref = useOnclickOutside(() => {
    // When the user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions()
  })
  return (
    <div ref={ref} className="relative flex w-full justify-between">
      <GoogleMapsLoader />
      {/* <div className="absolute right-0 top-[50%] translate-y-[-50%] bg-white"> */}
        <LocateMeButton onLocationChange={(e) => console.log(e)} />
      {/* </div> */}
    </div>
  )
}

export default PincodeInput
