'use client'
import {
  DropdownSelectionProps,
  secondaryDropdownCustomizationProps
} from '@components/types/CustomizationTypes'
import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import DropdownSelector from '../DropdownSelector'
import ProductPageSidePanelIndividualBox from '../ProductPageSidePanelIndividualBox'

interface SecondaryDropdownProps {
  data: secondaryDropdownCustomizationProps[]
  onSelect: (values: DropdownSelectionProps[]) => void
  isSubmitted?: boolean
}

const SecondaryDropdown: FC<SecondaryDropdownProps> = ({ data, onSelect, isSubmitted = false }) => {
  const [selectedValues, setSelectedValues] = useState<DropdownSelectionProps[]>([])
  const boxRefs = useRef<(RefObject<HTMLDivElement> | undefined)[]>([])

  useEffect(() => {
    boxRefs.current = Array(data.length)
      .fill(null)
      .map((_, i) => boxRefs.current[i] || React.createRef())
  }, [data])

  useEffect(() => {
    if (isSubmitted) {
      if (selectedValues.length === data.length) {
        return
      }

      const selectedTitles = selectedValues.map((ele) => ele.title)
      data.map((dropDOwn, ind) => {
        const title = dropDOwn.title
        if (!selectedTitles.includes(title)) {
          boxRefs.current[ind]?.current?.classList.add('animate-shake')
          const timer = setTimeout(() => {
            boxRefs.current[ind]?.current?.classList.remove('animation-shake')
          }, 2000)

          return () => {
            clearTimeout(timer)
          }
        }
      })
    }
  }, [data, isSubmitted, selectedValues])
  // useEffect(() => {
  //   data.map((ele, ind) =>
  //     !ele.isCompulsory
  //       ? setSelectedValues((prev) => {
  //           const tmp = [...prev]
  //           tmp[ind] = {
  //             choice: data[ind].choices[0],
  //             isPrimary: false,
  //             dependency: data[ind].dependency,
  //             title: ele.title
  //           }
  //           return tmp
  //         })
  //       : undefined
  //   )
  //   console.log({ selectedValues }, ' with data')
  // }, [data])

  return (
    <div className=" flex-col flex gap-3">
      {data.map((dropdown, dropdownIndex) => (
        <ProductPageSidePanelIndividualBox
          ref={boxRefs.current[dropdownIndex]}
          key={dropdown.title}
        >
          <div>{dropdown.title}</div>
          <DropdownSelector
            dropDownOptions={dropdown.choices.map((choice, ind) => ({
              id: ind.toString(),
              value: choice.choice
            }))}
            defaultIndex={dropdown.choices.findIndex((ele) => ele.isDefault)}
            onSelection={(val) => {
              const selectedVal = dropdown.choices.filter((choice) => choice.choice == val.value)
              setSelectedValues((prev) => {
                const tmp = [...prev]
                tmp[dropdownIndex] = {
                  choice: selectedVal[0],
                  dependency: data[dropdownIndex].dependency,
                  isPrimary:
                    data[dropdownIndex].coefficientDependency === '{"id":"0","value":"Primary"}',
                  title: data[dropdownIndex].title
                }
                onSelect(tmp)
                return tmp
              })
            }}
            title={dropdown.title}
            placeholder={!dropdown.isCompulsory ? dropdown.choices[0].choice : dropdown.placeholder}
            // preselecteIndex={0}
            preselecteIndex={
              dropdown.choices.length === 1 ? 0 : dropdown.isCompulsory ? undefined : 0
            }
            isCompulsory={dropdown.isCompulsory}
            isSubmitted={isSubmitted}
          />
        </ProductPageSidePanelIndividualBox>
      ))}
    </div>
  )
}

export default SecondaryDropdown
